// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-exhibition-js": () => import("./../../../src/pages/exhibition.js" /* webpackChunkName: "component---src-pages-exhibition-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-posters-js": () => import("./../../../src/pages/posters.js" /* webpackChunkName: "component---src-pages-posters-js" */),
  "component---src-pages-programme-js": () => import("./../../../src/pages/programme.js" /* webpackChunkName: "component---src-pages-programme-js" */),
  "component---src-pages-speakers-js": () => import("./../../../src/pages/speakers.js" /* webpackChunkName: "component---src-pages-speakers-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-templates-speaker-js": () => import("./../../../src/templates/speaker.js" /* webpackChunkName: "component---src-templates-speaker-js" */)
}

